import { AuthService, SharedService, User, EntityService } from '@app/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PmdSidebar } from '@shared/propeller';
import { AppGlobals } from '@app/app.global';
import { VoucherService } from '@modules/voucher/services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild(PmdSidebar) leftSidebar: { toggleSideBar: () => void; };
  @Input() isBetaVersion: boolean;
  @Input() isOpen: boolean;
  @Output() isOpenChange: EventEmitter<boolean>;
  isWithTags: any = false;
  isTaggingPending: boolean = false;
  url$: Observable<string> = new Observable<string>();
  currentURL: string;
  profileSidebarUrl: string[] = ['/profile', '/entity', '/voucher', '/user-management'];
  loggedinUser: User;
  showPushToPetpooja: boolean = false;
  isHoverMenu: boolean = false;
  isSidebarOpen = false;
  showPushToRetailPos: boolean = false;
  productTagsSubscription: Subscription;
  companyDetailsSubscription: Subscription;
  canShowBeneficiary = false;
  isLeadAdminModuleAccess: boolean = false;

  isSubmenuOpen: { [key: string]: boolean } = { banking: true };
  constructor(private sharedService: SharedService,
    public appGlobals: AppGlobals,
    public authService: AuthService,
    private entityService: EntityService,
    private voucherService: VoucherService,
    private router: Router,
    private cdRef: ChangeDetectorRef) {

    this.isOpenChange = new EventEmitter<boolean>(this.isOpen);

    this.loggedinUser = this.authService.user;
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));
    this.url$.subscribe((resp) => { this.currentURL = resp; });
  }

  ngOnInit(): void {
    const existingIsSidebarOpen = localStorage.getItem('isSidebarOpen');

    // * Enable lead admin module for specifc user
    const loggedinUser = this.authService.user;
    if (loggedinUser.email === this.appGlobals.leadAdminModuleAccessUser) {
      this.isLeadAdminModuleAccess = true;
    }

    if (existingIsSidebarOpen) {
      this.isOpen = JSON.parse(existingIsSidebarOpen);
      this.isOpenChange.emit(this.isOpen);
    }

    if (this.isOpen) {
      const sideBarElement = document.getElementById('leftSidebarMenu');

      document.body.classList.add('pmd-body-open');
      sideBarElement.classList.add('pmd-sidebar-open');
      this.cdRef.detectChanges();
    }


    if (!this.authService.user?.isAdmin) {
      this.productTagsSubscription = this.entityService.productTags$.subscribe((res) => this.isTaggingPending = res);
      this.companyDetailsSubscription = this.entityService.companyDetails$.subscribe((res) => { this.showPushToPetpooja = res; });
      this.getCompanyDetails();
    } else {
      this.showPushToPetpooja = true;
      this.showPushToRetailPos = true;
    }

    // check if can show beneficiary module
    this.sharedService.currentKYCStatus.subscribe((response) => {
      if (response && response == this.appGlobals.KYC_STATUSES.APPROVED) {
        this.canShowBeneficiary = true;
      } else {
        this.canShowBeneficiary = false;
      }
    })
  }

  toggleSubmenu(menu: string): void {
    this.isSubmenuOpen[menu] = !this.isSubmenuOpen[menu];
  }

  getCompanyDetails() {
    this.voucherService.getCompanyDetails().subscribe(res => {
      if (res["restaurantSyncCode"]) {
        this.showPushToPetpooja = true;
      }

      if (res["retailPosSyncCode"]) {
        this.showPushToRetailPos = true;
      }
    }, (error) => {
      this.sharedService.errorAlert(error)
    });
  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
    localStorage.setItem('isSidebarOpen', JSON.stringify(this.isOpen));
  }

  onMouseEnter() {
    const sideBarElement = document.getElementById('leftSidebarMenu');
    this.isHoverMenu = !this.isHoverMenu;
    if (sideBarElement) {
      sideBarElement.classList.add('pmd-sidebar-open-hover');
      this.cdRef.detectChanges();
    }
  }

  onMouseLeave() {
    const sideBarElement = document.getElementById('leftSidebarMenu');
    this.isHoverMenu = false;
    if (sideBarElement) {
      document.body.classList.remove('pmd-body-open');
      sideBarElement.classList.remove('pmd-sidebar-open-hover');
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.productTagsSubscription?.unsubscribe();
    this.companyDetailsSubscription?.unsubscribe();
  };

  // hideSideBar(){
  //   if(this.isSidebarOpen){
  //     this.sharedService.sideBarToggle.emit();
  //     this.isSidebarOpen = false;
  //   }
  // }
}