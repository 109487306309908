import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { PageMetaService, EntityService, AuthService, User, SharedService } from './core';
import { SidebarService } from './shared/sidebar/sidebar.service';
import { VoucherService } from './modules/voucher/services';
import { BankingService } from './modules/banking/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  user: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public entityService: EntityService,
    private pageMetaService: PageMetaService,
    private authService: AuthService,
    private sidebarService: SidebarService,
    private voucherService: VoucherService,
    private bankingService: BankingService,
    private sharedService: SharedService
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const route = this.getChild(this.route);

      route.data.subscribe(
        data => {
          this.titleService.setTitle('Invoice' + (data.title ? ' | ' + data.title : ''));
          this.pageMetaService.title.next(data.title);
          this.pageMetaService.backLink.next(data.backLink);
        }
      );
    });

    if (this.authService.isLoggedIn && this.user.isAdmin) {
      this.entityService.setCompanies().subscribe();
    }

    if (this.authService.isLoggedIn && !this.authService.user?.isAdmin) {
      this.sidebarService.getProductTags({ isWithTags: 'false' }).subscribe(res => {
        this.entityService.setProductTags(!!res?.count);
      });

      this.voucherService.getCompanyDetails().subscribe(res => {
        this.entityService.setCompanyDetails(!!res["restaurantSyncCode"]);
      });
    }
    this.getKYC();
  }

  getChild(route: ActivatedRoute) {
    return route.firstChild ? this.getChild(route.firstChild) : route;
  }

  /**
   * This will call getKYC() method and will be pushed in behavior subject
   */
  getKYC() {
    if (this.authService.user?.isOwner && !this.authService.user?.isSecondaryOwner) {
      this.bankingService.getKYC().subscribe((response: any) => {
        if (response && Object.keys(response).length) {
          this.sharedService.updateKYCStatus(response.kycStatus);
        } else {
          this.sharedService.updateKYCStatus(null);
        }
      });
    }
  }
}