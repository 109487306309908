<!--Sidebar-->
<pmd-sidebar aria-label="Left Sidebar"
	#leftSidebar
	placement="left"
	position="slidepush"
	class="pmd-sidebar pmd-sidebar-with-brand pmd-sidebar-slide-push"
	[ngClass]="{'pmd-sidebar-with-brand-beta' : isBetaVersion,'transition-none': isClassSidebarOpen == true}"
	role="navigation"
	id="leftSidebarMenu"
	[isOpen]="isSidebarOpen">
	<app-sidebar [isBetaVersion]="isBetaVersion"
		[isOpen]="isSidebarOpen"
		(isOpenChange)="onSidebarOpen($event)"> </app-sidebar>
</pmd-sidebar>
<!--Display an information alert containing a link -->
<div class="alert status-uploaded fs-12 py-1 msg-info me-3 d-none d-md-block"
	*ngIf="isBetaVersion"
	role="alert">
	<strong>
		{{ loggedinUser.isDemo ?
		'This is a demo account. All the data will be removed after 7 days.' :
		'This is a beta version of Invoice.' }}
	</strong>
</div>
<!--Navbar-->
<pmd-navbar class="khar_navbar navbar-expand-xl fixed-top bg-white"
	[ngClass]="{'no-beta-message-nav' : isBetaVersion,'transition-none': isClassSidebarOpen == true}"
	id="navbar">
	<app-navbar [(isSidebarOpen)]="isSidebarOpen"></app-navbar>
</pmd-navbar>
<!--/.Navbar-->

<!--Main Content Area-->

<main class="pmd-content home-layout"
	aria-label="Main Content"
	id="mainContent"
	[ngClass]="{'no-beta-message' : isBetaVersion,'transition-none': isClassSidebarOpen == true}">
	<div class="content_block">
		<div class="d-flex align-items-center d-block d-xl-none mb-3 mb-xl-0 container-fluid">
			<button *ngIf="backLink$ | async"
				[routerLink]="backLink$ | async"
				type="button"
				pmdRipple
				pmd-btn-fab
				color="light"
				size="xs"
				class="me-3">
				<i class="material-icons material-symbols-outlined pmd-icon-xs">arrow_back</i>
			</button>
			<div>
				<p class="mb-0 fs-6 fw-semibold">{{title$ | async}}</p>
				<!-- <app-breadcrumb></app-breadcrumb> -->
			</div>
		</div>
		<router-outlet></router-outlet>
	</div>
	<footer aria-label="Footer"
		class="footer-main">
		<app-footer class="login-app-footer"></app-footer>
	</footer>
</main>