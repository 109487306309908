<!--Main Content Area-->
<main aria-label="Main Content"
    class="pk-login-contener">
    <div class="row vh-sm-100 m-0">
        <div class="col-xl-6 col-sm-12 bg-white d-flex justify-content-center">
            <div class="pk-login-signup-card">
                <div class="pk-login-form">
                    <a class="navbar-brand pb-md-4 py-3 ms-0"
                        role="button"
                        (click)="onLoginLinkClick()"
                        aria-label="Invoice">
                        <img src="assets/images/Logo-dark.svg"
                            class="img-fluid logo-d">
                    </a>
                    <router-outlet></router-outlet>
                </div>
                <!-- Login Footer -->
                <div class="pmd-footer border-top">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12 col-md mb-2 mb-md-0">
                                <div class="pmd-site-info text-md-right">
                                    <p class=""
                                        tabindex="0"> &copy; {{currentYear}}
                                        Invoice
                                        by <b>Petpooja</b>
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-auto col-12">
                                <ul class="pmd-footer-nav pmd-footer-nav-divider">
                                    <li>
                                        <a href="javascript: void(0);"
                                            target="_blank"
                                            routerLink="/terms"
                                            aria-label="Press enter to open terms of use page">
                                            Terms of Use
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript: void(0);"
                                            target="_blank"
                                            routerLink="/privacy"
                                            aria-label="Press enter to open privacy policy page">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript: void(0);"
                                            target="_blank"
                                            routerLink="/refund-policy"
                                            aria-label="Press enter to open Refund Policy page">
                                            Refund
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /END/ Login Footer -->
            </div>
        </div>
        <div class="col-xl-6 col-sm-12 justify-content-center d-flex align-items-center pk-gradient-bg">
            <div class="pk-login-signup-right-card">
                <h1 class="mb-2 mb-sm-4 text-black mt-5 mt-xl-0">Start digitizing your invoices with us</h1>
                <p class="mb-sm-5 pb-sm-2 mb-0 lead">Our Intelligent Document Processing solution will help you
                    to automate document related workflows in your organization.</p>
                <div class="mb-sm-1 mb-3 img_graphics">
                    <img src="assets/images/graphics_login.svg"
                        class="img-fluid">
                </div>
            </div>
        </div>
        <!-- Login Footer -->
        <footer aria-label="Footer"
            class="d-block d-sm-none pk-login-signup-footer">
            <app-footer class="login-app-footer justify-content-center"></app-footer>
        </footer>
        <!-- /END/ Login Footer -->
    </div>
</main>
<!--/.Main Content Area-->