import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadFile } from 'ngx-uploader';

import { AppGlobals } from '@app/app.global';
import { Invoice } from '@modules/invoice/entities';
import { IInvoice, InvoiceStatus } from '@modules/invoice/interfaces';
import { AuthService } from '@app/core';
import { EntityService } from '@app/modules/entity/services';

type Tab = 'uploaded' | 'reviewed' | 'invalid';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  invoicesToBeUploaded$: BehaviorSubject<{ pages: { file: UploadFile, preview?: any }[], progress?: number, subscription?: Subscription, isUploadComplete?: boolean }[]>;
  activeInvoiceGroup$: BehaviorSubject<Tab>;
  invoiceFilters$: BehaviorSubject<{ [key: string]: { [key: string]: string | string[] } }>;
  uploadInvoiceEntity: BehaviorSubject<string>;

  constructor(private http: HttpClient, private appGlobals: AppGlobals, private authService: AuthService, private entityService: EntityService) {
    this.invoicesToBeUploaded$ = new BehaviorSubject<{ pages: { file: UploadFile, preview?: any }[], progress?: number, subscription?: Subscription, isUploadComplete?: boolean }[]>([]);
    this.uploadInvoiceEntity = new BehaviorSubject('');

    const activeInvoiceGroup = localStorage.getItem('activeInvoiceGroup');
    this.activeInvoiceGroup$ = new BehaviorSubject<Tab>(<Tab>activeInvoiceGroup ?? 'uploaded');
    this.activeInvoiceGroup$.subscribe(activeInvoiceGroup => {
      if (activeInvoiceGroup) {
        localStorage.setItem('activeInvoiceGroup', activeInvoiceGroup);
      }
    });

    const existingInvoiceListFilters = localStorage.getItem('invoiceListFilters');

    this.invoiceFilters$ = new BehaviorSubject<{ [key: string]: { [key: string]: string | string[] } }>(existingInvoiceListFilters ? JSON.parse(existingInvoiceListFilters) : null);
    this.invoiceFilters$.subscribe(filters => {
      if (filters) {
        localStorage.setItem('invoiceListFilters', JSON.stringify(filters));
      }
    });
  }

  getInvoiceTypes(): Observable<string[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-types`);
  }

  getInvoice(id: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-detail`, {
      params: { id }
    }).pipe(map((invoice: IInvoice) => new Invoice(invoice)));
  }

  getInvoices(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<{ invoices: Invoice[], statuses: { status: InvoiceStatus, count: number }[], documentTypes: { documentType: string, count: number }[], count: number }> {
    if (filters?.['status'] == InvoiceStatus.ALL) {
      delete filters['status'];
    }

    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-list`, {
      params: filters
    }).pipe(
      map((res: any) => ({ ...res, invoices: res.invoices.map((invoice: IInvoice) => new Invoice(invoice)) }))
    );
  }

  uploadInvoice(files: File[]): Observable<HttpEvent<any>> {
    let formData: FormData = new FormData();

    files.forEach(file => formData.append('file', file, file.name));

    if (this.authService.user.isOwner) {
      return this.http.post<any>(`${this.appGlobals.bypassApiUrl.invoice}upload-invoice`, formData, {
        reportProgress: true, observe: "events",
        params: { entity: this.getUploadInvoiceEntity() }
      });
    } else {
      return this.http.post<any>(`${this.appGlobals.bypassApiUrl.invoice}upload-invoice`, formData, {
        reportProgress: true, observe: "events"
      });
    }
  }

  changeStatus(ids: string[], status: ('Active' | 'Inactive' | 'Archieved' | 'Deleted')) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}change-invoice-statuses`, {
      params: { 'ids[]': ids, status }
    });
  }

  getInvoiceCount(): Observable<{ [key: string]: number; }> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-count`);
  }

  getToBeReviewedInvoices(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    if (filters?.['status'] == InvoiceStatus.ALL) {
      delete filters['status'];
    }

    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-list-to-review`, {
      params: filters
    });
  }

  reviewInvoice(invoice) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}review-invoice`, invoice);
  }

  autoSaveReview(invoice) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}auto-save-invoice`, invoice);
  }

  saveReviewDuration(id, duration) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}save-review-duration`, { id, duration });
  }

  getRejectionReasons() {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}rejection-reasons`);
  }

  rejectInvoice(id, rejectReasonId, otherReason) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}reject-invoice`, { id, rejectReasonId, otherReason });
  }

  getProductKeys(): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}product-keys`);
  }

  getTotalKeys(): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}total-keys`);
  }

  getOriginalInvoice(invoiceId: string, filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}original-invoice`, {
      params: { invoiceId, ...filters }
    });
  }

  getInvoiceDateByNumber(queryParams): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-date`, {
      params: queryParams
    });
  }

  getInvoiceByDate(queryParams): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}invoice-number`, {
      params: queryParams
    });
  }

  transferInvoiceEntity(body) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}transfer-invoice`, body);
  }

  setUplodInvoiceEntity(entity: string) {
    this.uploadInvoiceEntity.next(entity);
  }

  getUploadInvoiceEntity() {
    return this.uploadInvoiceEntity.value;
  }

  getTdsRates() {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tds-rates`);
  }

  getTdsNatures() {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tds-natures`);
  }

  getTcsRates() {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tcs-rates`);
  }

  getTcsNatures() {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}tcs-natures`);
  }

  getPanDetail(pan: string) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}get-pan-detail`, { pan });
  }

  getGstDetail(gst: string) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}get-gst-detail`, { gst });
  }

  getSellerPan(queryParams): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}seller-pan`, {
      params: queryParams
    });
  }

  getProductDescriptions(queryParams): Observable<any[]> {
    if (!queryParams?.companyName) {
      return of([]);
    }

    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}suggested-product-names`, {
      params: queryParams
    });
  }

  saveActivityLog(activityLog) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}activity-log`, activityLog);
  }

  getActivityLog(queryParams): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}activity-log`, {
      params: queryParams
    });
  }

  addIpTrace(body) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.metadata}ip-tracing`, body);
  }

  getSupplierTags(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}supplier-tags`, { params: filters });
  }

  getCompanyGstPan(invoice: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}company-gst-pan`, {
      params: { invoice }
    });
  }

}