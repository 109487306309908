import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from '@app/app.global';
@Injectable({
  providedIn: 'root'
})
export class BankingService {

  constructor(
    private http: HttpClient,
    private appGlobals: AppGlobals
  ) { }

  addKYC(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/add`, requestBody);
  }

  getKYC() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}kyc/detail`);
  }

  getFirmTypeList() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}kyc/kyc-documents-list`);
  }

  uploadKYCDoc(requestBody, firmType?: string) {
    let additionalFlag = firmType ? { params: { 'firmType': firmType } } : {};
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/document-upload`, requestBody, additionalFlag);
  }

  sendOTP(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/send-otp`, requestBody);
  }

  verifyOTP(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/verify-otp`, requestBody);
  }

  getSupplierBalanceDetail() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/balance-detail`);
  }

  getSuppliers(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/list`, {
      params: queryParams
    })
  }

  getSuppierAllInvoice(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/all-invoice`, {
      params: queryParams
    })
  }

  getbeneficiaryList(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}beneficiary-list`, {
      params: queryParams
    })
  }

  getAllBeneficiaryList() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}all-beneficiary-list`)
  }

  getBeneficiaries(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}beneficiary-list`, {
      params: queryParams
    })
  }

  createTransaction(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/create-transaction`, requestBody);
  }

  supplierCashTransaction(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/supplier-cash-transaction`, requestBody);
  }

  supplierCashPayment(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/supplier-cash-payment`, requestBody);
  }

  makePayment(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/make-payment`, requestBody);
  }

  createBeneficiary(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}create-beneficiary`, requestBody);
  }

  verifyBeneficiaryOTP(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}beneficiary-verify-otp`, requestBody);
  }

  getSuppliersForDropdown() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/suppliers`);
  }
  getTransactionsList(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}payment/transaction-list`, { params: queryParams });
  }

  getInvoicesBySeller(queryParams) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/invoices`, {
      params: queryParams
    });
  }

  getOwnerBalance() {
    return this.http.get(`${this.appGlobals.apiUrl.payment}supplier/balance-detail`);
  }

  getPaymentInvoiceLogs(invoice) {
    return this.http.get(`${this.appGlobals.apiUrl.payment}payment/invoice/` + invoice);
  }

  adjustPayment(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/adjust-payment`, requestBody);
  }

  withoutKYCPayment(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}payment/without-kyc-payment`, requestBody);
  }

  uploadKYCDocCancelledCheque(requestBody, firmType?: string) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/document-upload-cancelled-cheque`, requestBody);
  }

  deleteUploadedKycDocument(requestBody) {
    return this.http.delete(`${this.appGlobals.apiUrl.payment}kyc/delete-kyc-document`, { body: requestBody });
  }
  updateKYCMessageStatus(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}kyc/kyc-message-status-update`, requestBody);
  }

  updateBeneficiary(requestBody) {
    return this.http.post(`${this.appGlobals.apiUrl.payment}beneficiary-status-update`, requestBody);
  }

  
}
