import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@app/core';
import { ZipDownloadService } from '@modules/invoice/services';

@Component({
  selector: 'app-pdf-zoom',
  templateUrl: './pdf-zoom.component.html',
  styleUrls: ['./pdf-zoom.component.scss']
})
export class PdfZoomComponent implements OnChanges {
  @Input() name?: string;
  @Input() path: string;
  @Input() page?: number;
  @Input() sources: any;
  @Input() isFullScreen: boolean;
  @Input() isDuplicate: boolean;
  @Input() showCloseIcon: boolean;
  @Input() showFullScreenBtn: boolean;
  @Input() zoomScale: string = "'page-width'";
  @Output() closeEvent: EventEmitter<{ isCloseClicked: boolean }> = new EventEmitter(null);
  @Output() fullScreenEvent: EventEmitter<{ isFullScreen: boolean }> = new EventEmitter(false);
  isDragging = false;
  startX: number;
  startY: number;
  initialPosX: number = 0;
  initialPosY: number = 0;
  posX: number = 0;
  posY: number = 0;
  zoom: number = 1;
  minZoom: number = 1;
  maxZoom: number = 3;
  zoomIncrement: number = 0.4;
  rotation: number = 0;
  downlaodVisible: boolean = false;
  loggedinUser: User;

  constructor(
    public zipDownloadService: ZipDownloadService,
    public router: Router,
    private authService: AuthService) {

    this.downlaodVisible = (/^\/invoice\/[a-f\d]{24}$/i.test(this.router.url) || /^\/invoice\/[a-f0-9]{24}\/image-only$/i.test(this.router.url)) && this.authService.user.isAdmin ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isFullScreen?.currentValue) {
      document.body.style.overflow = 'hidden';
    } else if (changes) {
      document.body.style.overflow = '';
      this.reset();
    }
  }

  downloadFilesAsZip() {
    this.zipDownloadService.downloadFilesAsZip(this.sources);
  }

  zoomIn() {
    if (this.zoom < this.maxZoom) {
      this.zoom += this.zoomIncrement;
    }
  }

  zoomOut() {
    if (this.zoom > this.minZoom) {
      this.zoom -= this.zoomIncrement;
    }
  }

  @HostListener('wheel', ['$event'])
  zoomInOut(event: WheelEvent) {
    event.preventDefault();

    const delta = Math.max(-1, Math.min(1, event.deltaY || -event.detail));

    // Zoom out
    if (delta > 0 && this.zoom > this.minZoom) {
      this.zoom -= this.zoomIncrement;
    }
    // Zoom in
    else if (delta < 0 && this.zoom < this.maxZoom) {
      this.zoom += this.zoomIncrement;
    }
  }

  rotate() {
    this.rotation += 90;
    this.rotation %= 360;
  }

  antiRotate() {
    this.rotation -= 90;
    this.rotation %= 360;
  }

  /**
   * This method will toggle fullscreen mode
   * and based on mode it will prevent page scroll
   */
  toggleFullScreen() {
    const element = document.documentElement;

    if (this.isFullScreen && document.exitFullscreen) {
      document.exitFullscreen();
    } else if (!this.isFullScreen && element.requestFullscreen) {
      element.requestFullscreen();
    }
  }

  /**
   * Below Host listener is required to listen fullscreen mode change
   * It will be used to exit from fullscreen mode
   */
  @HostListener('document:fullscreenchange', ['$event'])
  fullScreen() {
    this.isFullScreen = !this.isFullScreen;
    document.body.style.overflow = this.isFullScreen ? '' : 'hidden';
    this.fullScreenEvent.emit({ isFullScreen: this.isFullScreen });
  }

  @HostListener('mousedown', ['$event'])
  startDrag(e: MouseEvent) {
    e.preventDefault();
    this.isDragging = true;
    this.startX = e.clientX;
    this.startY = e.clientY;
  }

  @HostListener('mouseup')
  endDrag() {
    this.isDragging = false;
    this.initialPosX = this.posX;
    this.initialPosY = this.posY;
  }

  @HostListener('mousemove', ['$event'])
  drag(e: MouseEvent) {
    if (this.isDragging) {
      e.preventDefault();

      this.posX = this.initialPosX + e.clientX - this.startX;
      this.posY = this.initialPosY + e.clientY - this.startY;
    }
  }

  leave() {
    this.endDrag();
  }

  reset() {
    this.isDragging = false;
    this.zoom = 1;
    this.rotation = 0;
    this.initialPosX = 0;
    this.initialPosY = 0;
    this.posX = 0;
    this.posY = 0;
  }

  /**
   * This method will emit close event to close modal
   */
  onClose() {
    this.reset();
    this.closeEvent.emit({ isCloseClicked: true });
  }
}