import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  AfterViewInit,
  HostListener,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';

import { document, window } from '../utils';

@Component({
  selector: '[pmdMultiSidebar]',
  exportAs: 'pmdMultiSidebar',
  template: '<ng-content></ng-content>',
})

export class PmdMultiSidebar {
  @Input() closeOthers: boolean;
  public groups: PmdSidebar[] = [];
  constructor(public renderer: Renderer2) {
  }
}

@Component({
  selector: 'pmd-sidebar, [pmd-sidebar]',
  templateUrl: 'sidebar.component.html',
  host: {
    'class': 'pmd-sidebar pmd-z-depth',
    '[class.pmd-sidebar-open]': 'isOpen',
    '[class.pmd-sidebar-right-fixed]': 'placement == "right" && position == "fixed"',
    '[class.pmd-sidebar-left]': 'placement == "left"',
    '[class.pmd-sidebar-slide-push]': 'position == "slidepush"',
    '[class.pmd-sidebar-left-fixed]': 'position == "fixed" && placement == "left"',
  }
})

export class PmdSidebar implements AfterViewInit, OnInit {

  // backdrop = this.renderer.createElement('div');

  @Input() placement: string = '';
  @Input() position: string = '';
  @Input() isOpenWidth: number;
  @Input() isOpen: boolean;
  @Input() overlay: boolean = true;

  @Input() multiSidebar: PmdMultiSidebar;

  @Output('showPmdSidebar') public showPmdSidebar: EventEmitter<any> = new EventEmitter();
  @Output('hidePmdSidebar') public hidePmdSidebar: EventEmitter<any> = new EventEmitter();

  isSidebarOpen: boolean

  div = this.renderer.createElement('div');
  text = this.renderer.createText('');
  parent = this._elementRef.nativeElement.parentNode;
  refChild = this._elementRef.nativeElement;
  body = document.getElementsByTagName('body')[0];

  mainSideElement: any;

  constructor(public renderer: Renderer2, private _elementRef: ElementRef) {
    this.performTask();
    this.toggleClassBody();
  }

  ngOnInit() {
    const navli = this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    this.div.addEventListener('click', this._clickHandler);
    this.toggleClassBody();
  }

  ngAfterViewInit() {
    if (!this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
      if (this.isOpen) {
        this.addClassOverly();
      } else {
        this.removeClassOverly();
      }
    }
    if (window.innerWidth < this.isOpenWidth) {
      this.hideSidebarisOpenWidth();
    } else {
      this.showSidebarisOpenWidth();
    }
  }

  clickHandler(event: any) {
    this.removeClassOverly();
    this.body.classList.remove("pmd-body-open");
    this.isOpen = false;
    this.hidePmdSidebar.emit();
  }
  _clickHandler = this.clickHandler.bind(this);

  toggleSidebarOverlay(): void {
    this.removeClassOverly();
    this.isOpen = false;
  }
  toggleSideBar(): void {
    this.isOpen = !this.isOpen;
    this.toggleClassBody();
    if (this.isOpen) {
      this.showPmdSidebar.emit();
      this.closeOtherPanels(this);
      this.addGroup(this);
    } else {
      this.removeGroup(this);
      this.hidePmdSidebar.emit();
    }
    if (!this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
      if (this.isOpen) {
        this.addClassOverly();
      } else {
        this.removeClassOverly();
      }
    }
    this.setFocus(this)
  }

  setFocus(group: any) {
    if (group) {
      setTimeout(() => {
        const ref = group._elementRef.nativeElement.querySelector('[tabindex="0"]');
        if (ref) ref.focus();
      }, 0)
    } else {
      if (this.mainSideElement) this.mainSideElement.focus();
    }
  }

  onChange(value: boolean): void {
    this.isOpen = value;
  }

  toggleClassBody() {
    if (this.isOpen) {
      this.body.classList.add("pmd-body-open");
    } else {
      this.body.classList.remove("pmd-body-open");
    }
  }

  performTask() {
    this.renderer.addClass(this.div, 'pmd-sidebar-overlay');
    this.renderer.appendChild(this.div, this.text);
    this.renderer.insertBefore(this.parent, this.div, this.refChild);
  }

  addClassOverly() {
    if (this.overlay) {
      this.renderer.addClass(this.div, 'pmd-sidebar-overlay-active');
    }
    // this.renderer.addClass(this.renderer.selectRootElement('.pmd-sidebar-overlay'), 'pmd-sidebar-overlay-active');
  }

  removeClassOverly() {
    this.renderer.removeClass(this.div, 'pmd-sidebar-overlay-active');
    // this.renderer.removeClass(this.renderer.selectRootElement('.pmd-sidebar-overlay'), 'pmd-sidebar-overlay-active');
  }

  addClassSlidepush() {
    this._elementRef.nativeElement.classList.add("pmd-sidebar-slide-push");
  }

  removeClassSlidepush() {
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-slide-push");
  }

  addClassOpen() {
    this._elementRef.nativeElement.classList.add("pmd-sidebar-open");
  }
  removeClassOpen() {
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-open");
  }

  addClassLeftFixed() {
    this._elementRef.nativeElement.classList.add("pmd-sidebar-left-fixed");
  }

  removeClassLeftFixed() {
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-left-fixed");
  }

  addClassIsSlidepush() {
    this._elementRef.nativeElement.classList.add("is-slidepush");
  }

  removeClassIsSlidepush() {
    this._elementRef.nativeElement.classList.remove("is-slidepush");
  }

  hideSlidepush() {
    this.removeClassSlidepush();
    this.isOpen = false;
    this.addClassLeftFixed();
    this.body.classList.remove("pmd-body-open");
  }

  showSlidepush() {
    this.addClassSlidepush();
    this.isOpen = true;
    this.removeClassLeftFixed();
    this.body.classList.add("pmd-body-open");
  }

  hideSidebarisOpenWidth() {
    const navli = this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    if (!this._elementRef.nativeElement.classList.contains("is-slidepush")) {
      this.addClassIsSlidepush();
      if (this.position == "slidepush" && this.placement == "left") {
        this.hideSlidepush();
      }
      if (this.position == "fixed") {
        if (this.placement == "left" || this.placement == "right") {
          this.isOpen = false;
          this.removeClassOverly();
        }
      }
    }
    for (const button of navli) {
      button.addEventListener('click', this._clickHandler)
    }
  }

  showSidebarisOpenWidth() {
    const navli = this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    if (this._elementRef.nativeElement.classList.contains("is-slidepush")) {
      this.removeClassIsSlidepush();
      if (this.position == "slidepush" && this.placement == "left") {
        this.showSlidepush();
      }
      if (this.isOpen = true) {
        if (this.position == "fixed") {
          if (this.placement == "left" || this.placement == "right") {
            this.isOpen = true;
            this.addClassOverly();
          }
        }
      }
    }
    for (const button of navli) {
      button.removeEventListener('click', this._clickHandler)
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent): any {
    if (e.keyCode === 9 && this.multiSidebar && this.multiSidebar.groups.length > 0) {
      const focusableEls1 = this._elementRef.nativeElement.querySelectorAll(
        'input[tabindex="0"], button[tabindex="0"], a[tabindex="0"]'
      );
      const focusableEls = Array.from(focusableEls1)
        .filter((el: any) => !el.disabled);
      const firstFocusableEl: any = focusableEls[0];
      const lastFocusableEl: any = focusableEls[focusableEls.length - 1];

      this._elementRef.nativeElement.addEventListener('keydown', (e: any) => {
        var isTabPressed = e.keyCode === 9; // isTabPressed
        if (!isTabPressed) return;

        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      });
    }
    if (e.keyCode === 27) {
      let overlayEl = document.getElementsByClassName('pmd-sidebar-overlay pmd-sidebar-overlay-active');
      let secondLevelEl = document.getElementsByClassName('sidebar-second-level');
      let thirdLevelEl = document.getElementsByClassName('sidebar-third-level');
      if (overlayEl.length > 0 && secondLevelEl[0].children.length == 0 && thirdLevelEl[0].children.length == 0) {
        overlayEl[0].click();
      }
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    if (!this._elementRef.nativeElement.classList.contains("is-slidepush")) {
      if (this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
        this.removeClassOverly();
      }
    }
    if (event.target.innerWidth < this.isOpenWidth) {
      this.hideSidebarisOpenWidth();
    } else {
      this.showSidebarisOpenWidth();
    }
  }


  closeOtherPanels(openGroup: PmdSidebar): void {
    if (!this.multiSidebar) {
      return;
    }
    this.multiSidebar.groups.forEach((group: PmdSidebar) => {
      if (group !== openGroup) {
        // group.isCollapsed = true;
        // group.hide()
      }
    });
  }

  addGroup(group: PmdSidebar): void {
    if (!this.multiSidebar) {
      return;
    }
    if (this.multiSidebar.groups.indexOf(group) === -1) {
      this.multiSidebar.groups.push(group);
    }
  }

  removeGroup(group: PmdSidebar): void {
    // const index = this.multiSidebar.groups.indexOf(group);
    // var length = this.multiSidebar.groups.length;
    if (this.multiSidebar && this.multiSidebar.groups.length > 0) {
      const indexLenth = this.multiSidebar.groups.length;
      const index = this.multiSidebar.groups.indexOf(group);
      // if (index === 0) {
      //   this.multiSidebar.groups.forEach((groupss:  PmdSidebar) => {
      //     groupss.isOpen = false;
      //   });
      // } 
      if (index !== -1) {
        for (let i = 0; i < indexLenth; i++) {
          if (i >= index) {
            this.multiSidebar.groups[i].isOpen = false;
          }
        }
        this.setFocus(this.multiSidebar.groups[index - 1])
      }
      if (index === 0) {
        this.multiSidebar.groups = []
      }
    } else {
      this.isOpen = false;
    }
    // this.isOpen = false
  }

  setMainElem(el: any) {
    this.mainSideElement = el;
  }

}