<div class="row align-items-center pagination-container justify-content-between"
    *ngIf="count">
    <div class="d-flex col-auto  align-items-center">
        <span class="text-muted">{{pageName}}s per page</span>
        <ng-select class="pmd-select pf-select-page pmd-select-sm pmd-select-outline ms-2 khr-page-num"
            [labelForId]="id"
            appearance="outline"
            [(ngModel)]="pageSize"
            (change)="onPageSizeChange($event)"
            [clearable]="false"
            [searchable]="false"
            id="pageNumber-uploaded">
            <ng-option *ngFor="let page of pageSizes"
                [value]="page">{{page}}
            </ng-option>
        </ng-select>
    </div>
    <p class="col-auto text-center text-sm-start text-md-center mt-3 mt-md-0 mb-md-0 fs-12 order-sm-1 order-md-0">
        <strong class="pf-fw-semibold">
            {{(page - 1) * pageSize + 1}} - {{count > page * pageSize ? page * pageSize : count}}
        </strong> of {{count}} {{pageName}}(s)
    </p>
    <nav aria-label="Page navigation example"
        class="col-auto">
        <pagination *ngIf="count > pageSize"
            [align]="true"
            [maxSize]="3"
            [boundaryLinks]="true"
            [directionLinks]="false"
            [firstText]="leftArrow"
            [lastText]="rightArrow"
            [page]="+page"
            [itemsPerPage]="+pageSize"
            [totalItems]="count"
            (pageChanged)="onPageChange($event)"
            [rotate]="false">
        </pagination>
    </nav>
</div>